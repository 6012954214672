<template>
  <div>
    <v-container fluid class="m-0 p-0">
      <v-parallax
        height="300"
        src="https://images.unsplash.com/photo-1507301319219-826fa42854f3?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1472&q=80"
      ></v-parallax>
    </v-container>

    <v-container
      v-if="expertsProfile.item == null"
      style="margin-top: -18rem; padding-left: 3rem; padding-right: 3rem"
    >
      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title>
              <h3>Expertenprofil</h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-alert
                    color="blue"
                    elevation="2"
                    icon="mdi-information"
                    outlined
                  >
                    <span
                      ><b
                        >Sie sind ein Experte im Outdoor-Bereich, ein erfahrener
                        Kursleiter oder Survival-Trainer?</b
                      >
                      Dann haben Sie hier die Möglichkeit, sich zu präsentieren
                      und unter der Rubrik "Angebote" auch Ihre Kurse
                      anzubieten. Bei diesen Angeboten legen wir besonderen Wert
                      auf die Personen und nicht auf Unternehmen. Natürlich
                      können Sie sich als Unternehmen registrieren, jedoch
                      möchten wir den potenziellen Kursteilnehmern ein Gesicht
                      hinter den Angeboten zeigen. Beschreiben Sie Ihr
                      Kursangebot und das, was Sie individuell auszeichnet, so
                      detailliert wie möglich. Falls Ihr Unternehmen mehrere
                      Kursangebote und Trainer hat, bitten wir jeden Trainer,
                      ein Profil zu erstellen und seine Kurse einzutragen.</span
                    >
                  </v-alert>
                </v-col>
                <v-col md="12" sm="12">
                  <template v-if="isLoading">
                    <v-progress-circular indeterminate></v-progress-circular>
                  </template>
                  <template v-else>
                    <NoProfile></NoProfile>
                  </template>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12">
                  <v-alert
                    type="info"
                    elevation="2"
                    icon="mdi-information"
                    outlined
                  >
                    <span
                      >Bitte beachten Sie, dass Sie Ihr Profil erst nach
                      Freischaltung durch die Redaktion veröffentlichen können.
                      Nach dem Beantragen der Freischaltung prüfen wir Ihr
                      Profil auf Seriösität und schalten dies schnellstmöglich
                      frei.</span
                    >
                  </v-alert>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container
      style="margin-top: -18rem; padding-left: 3rem; padding-right: 3rem"
      v-else
    >
      <v-row>
        <v-col>
          <v-card>
            <v-card-title>
              <h3>Expertenprofil</h3>
            </v-card-title>
            <v-card-text>
              <v-row>
                <v-col>
                  <span v-if="expertsProfile.item.subscription">
                    Aktuelle Mitgliedschaft: {{ expertsProfile.item.subscription.name }} - Gültig bis {{ parseDate(expertsProfile.item.subscription.endDate) }}
                  </span>
                  <span v-else>
                    Kostenlose Mitgliedschaft
                  </span>
                </v-col>
              </v-row>
              <v-row>
                <v-col md="12" sm="12">
                  <v-row>
                    <v-col>
                      <template v-if="expertsProfile.item.isPublic"> </template>
                      <template v-else>
                        <template v-if="expertsProfile.item.pendingUnlock">
                          <v-chip :color="getStateColor()" text-color="white">
                            <span>Profil wird von Redaktion geprüft</span>
                          </v-chip>
                        </template>
                        <template v-else>
                          <v-chip :color="getStateColor()" text-color="white">
                            <span>Profil noch nicht freigeschaltet</span>
                          </v-chip>
                        </template>
                        <v-chip v-if="expertsProfile.item.isLocked" color="red">
                          <v-icon>mdi-lock</v-icon>
                          <span>Profil gesperrt</span>
                        </v-chip>
                      </template>
                    </v-col>
                    <v-col
                      v-if="
                        expertsProfile.item.isPublic != true &&
                        !expertsProfile.item.pendingUnlock
                      "
                    >
                      <v-btn
                        color="primary"
                        class="mr-5"
                        @click="submitProfile()"
                        block
                        >Freischaltung beantragen</v-btn
                      >
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="3">
                      <v-card>
                        <v-card-title>
                          <h3>Ihr Bild</h3>
                        </v-card-title>
                        <v-card-text>
                          <template
                            v-if="
                              expertsProfile.item.photo != null &&
                              expertsProfile.item.photo.thumbnailUrl != null
                            "
                          >
                            <v-img
                              :src="
                                getAbsoluteImageUrl(
                                  expertsProfile.item.photo.thumbnailUrl
                                )
                              "
                              :lazy-src="
                                getAbsoluteImageUrl(
                                  expertsProfile.item.photo.thumbnailUrl
                                )
                              "
                              aspect-ratio="1"
                              max-height="300"
                              alt="Profile Image"
                            ></v-img>
                          </template>
                          <template v-else>
                            <v-img
                              :src="`https://picsum.photos/500/300?image=1`"
                              :lazy-src="`https://picsum.photos/10/6?image=1`"
                              aspect-ratio="1"
                              max-height="300"
                              alt="Profile Image"
                            ></v-img>
                          </template>
                        </v-card-text>
                      </v-card>
                    </v-col>

                    <v-col cols="9">
                      <v-card min-height="380">
                        <v-card-title>
                          <h3>Ihr Profilbild oder Logo</h3>
                        </v-card-title>
                        <v-card-text>
                          <v-form
                            @submit.prevent="newImage"
                            enctype="multipart/form-data"
                            id="upload-form"
                          >
                            <v-row>
                              <v-col cols="12">
                                <v-file-input
                                  accept="image/png, image/jpeg, image/bmp"
                                  name="file"
                                  v-model="file"
                                  label="Datei Upload"
                                  filled
                                  prepend-icon="mdi-camera"
                                  multiple="multiple"
                                ></v-file-input>

                                <div
                                  v-show="submitted && !file"
                                  class="invalid-feedback"
                                >
                                  Bitte Datei auswählen
                                </div>
                                <v-row>
                                  <v-col cols="12">
                                    <v-btn
                                      type="submit"
                                      form="upload-form"
                                      color="primary"
                                      :disabled="submitted || !file"
                                    >
                                      Hochladen...
                                    </v-btn>
                                    <v-progress-circular
                                      indeterminate
                                      color="primary"
                                      v-if="submitted"
                                    ></v-progress-circular>
                                  </v-col>
                                </v-row>
                                <v-row>
                                  <v-col cols="12">
                                    <ul style="list-style: none">
                                      <li
                                        v-for="s in uploadSuccesses"
                                        :key="s.item"
                                        class="successfull"
                                      >
                                        Erfolgreich: {{ s }}
                                      </li>
                                      <li
                                        v-for="s in uploadErrors"
                                        :key="s.item"
                                        class="failed"
                                      >
                                        Fehlgeschlagen: {{ s }}
                                      </li>
                                    </ul>
                                  </v-col>
                                </v-row>
                              </v-col>
                            </v-row>
                          </v-form>
                        </v-card-text>
                      </v-card>
                    </v-col>
                  </v-row>
                  <v-form v-model="valid">
                    <v-row>
                      <v-col cols="6">
                        <v-card>
                          <v-card-title>
                            <h3>Allgemeine Informationen</h3>
                          </v-card-title>
                          <v-card-subtitle>
                            <span
                              >Zum Anpassen der deaktivierten Felder nutzen Sie
                              bitte in der Sidebar den Menüpunkt "Mein
                              Account".</span
                            >
                          </v-card-subtitle>
                          <v-card-text>
                            <v-text-field
                              v-model="expertsProfile.item.accountMail"
                              label="Account E-Mail *"
                              outlined
                              disabled
                              dense
                            ></v-text-field>

                            <v-text-field
                              v-model="expertsProfile.item.displayName"
                              label="Wie möchten Sie in der Liste angezeigt werden? z.B. Super Adventures. (optional)"
                              outlined
                              dense
                            ></v-text-field>

                            <v-row>
                              <v-col>
                                <v-select
                                  label="Geschlecht"
                                  v-model="expertsProfile.item.geschlecht"
                                  :items="sex"
                                  item-text="text"
                                  item-value="value"
                                  outlined
                                  required
                                  dense
                                ></v-select>
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-model="expertsProfile.item.firstName"
                                  label="Vorname *"
                                  outlined
                                  disabled
                                  dense
                                ></v-text-field>
                              </v-col>

                              <v-col>
                                <v-text-field
                                  v-model="expertsProfile.item.lastName"
                                  label="Nachname *"
                                  disabled
                                  outlined
                                  dense
                                ></v-text-field>
                              </v-col>
                            </v-row>

                            <v-dialog
                              ref="dialog"
                              v-model="modal"
                              :return-value.sync="expertsProfile.item.birthday"
                              persistent
                              width="290px"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                  v-model="expertsProfile.item.birthday"
                                  label="Geburstdatum"
                                  prepend-icon="mdi-calendar"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                ></v-text-field>
                              </template>
                              <v-date-picker
                                v-model="expertsProfile.item.birthday"
                                scrollable
                                locale="de-de"
                              >
                                <v-spacer></v-spacer>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="modal = false"
                                >
                                  Abbrechen
                                </v-btn>
                                <v-btn
                                  text
                                  color="primary"
                                  @click="
                                    $refs.dialog.save(
                                      expertsProfile.item.birthday
                                    )
                                  "
                                >
                                  OK
                                </v-btn>
                              </v-date-picker>
                            </v-dialog>
                          </v-card-text>
                        </v-card>

                        <v-card class="mt-2">
                          <v-card-title>
                            <h3>Kontaktinformationen</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-text-field
                              v-model="expertsProfile.item.contactMail"
                              label="Kontakt E-Mail *"
                              outlined
                              required
                              dense
                            ></v-text-field>

                            <v-text-field
                              v-model="expertsProfile.item.phone"
                              label="Telefon"
                              outlined
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="expertsProfile.item.mobile"
                              label="Smartphone"
                              outlined
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="expertsProfile.item.street"
                              label="Straße & Hausnummer"
                              outlined
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="expertsProfile.item.plz"
                              label="Postleitzahl *"
                              outlined
                              required
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="expertsProfile.item.city"
                              label="Stadt *"
                              outlined
                              required
                              dense
                            ></v-text-field>
                            <v-select
                              v-model="expertsProfile.item.country"
                              label="Land *"
                              :items="countries"
                              outlined
                              required
                              dense
                            ></v-select>
                          </v-card-text>
                        </v-card>
                      </v-col>

                      <v-col cols="6">
                        <v-card>
                          <v-card-title>
                            <h3>Kategorien</h3>
                          </v-card-title>
                          <v-card-subtitle>
                            Wählen Sie die Kategorien aus, in denen Sie sich als
                            Experte eintragen möchten. Mit Ihrer Mitgliedschaft
                            können Sie sich in bis zu
                            {{ getMaxSubscription() }} Kategorie(n) eintragen.
                          </v-card-subtitle>
                          <v-card-text>
                            <v-select
                              v-model="selectedCategories"
                              label="Kategorien auswählen"
                              :items="categories"
                              item-text="title"
                              item-value="id"
                              small-chips
                              color="primary"
                              multiple
                              outlined
                              required
                              dense
                            >
                              <template #selection="{ item }">
                                <v-chip :color="item.chipColor">{{
                                  item.title
                                }}</v-chip>
                              </template>
                            </v-select>
                            <span
                              class="red--text"
                              v-if="maxSubscriptionOverload()"
                              >Zu viele Kategorien ausgewählt</span
                            >
                          </v-card-text>
                        </v-card>

                        <v-card class="mt-2">
                          <v-card-title>
                            <h3>Social Media</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-text-field
                              v-model="expertsProfile.item.website"
                              label="Webseite"
                              outlined
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="expertsProfile.item.social_Facebook"
                              label="Facebook"
                              outlined
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="expertsProfile.item.social_Instagram"
                              label="Instagram"
                              outlined
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="expertsProfile.item.social_Twitter"
                              label="Twitter"
                              outlined
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="expertsProfile.item.social_LinkedIn"
                              label="LinkedIn"
                              outlined
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="expertsProfile.item.social_Youtube"
                              label="YouTube Kanal Link"
                              outlined
                              dense
                            ></v-text-field>
                            <v-text-field
                              v-model="
                                expertsProfile.item.embed_Youtube_ChannelTrailer
                              "
                              label="YouTube Vorstellungsvideo (Kanaltrailer)"
                              outlined
                              dense
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-card>
                          <v-card-title>
                            <h3>Titel</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-text-field
                              v-model="expertsProfile.item.caption"
                              label="Als was würden Sie sich bezeichnen? *"
                              placeholder="(Survival Trainer, Fitness-Experte, Expeditionsleiter, Reiseführer, Kursleiter, etc.)"
                              outlined
                              required
                              dense
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-card>
                          <v-card-title>
                            <h3>Über mich</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-textarea
                              v-model="expertsProfile.item.description"
                              label="Beschreiben Sie sich kurz *"
                              placeholder="Ich bin ein Survival Trainer und biete Ihnen Kurse an, wie Sie sich in der Wildnis selbst versorgen können..."
                              outlined
                              dense
                              required
                            ></v-textarea>

                            <h3>Sprachen</h3>
                            <v-text-field
                              v-model="expertsProfile.item.languages"
                              label="Welche Sprachen sprechen Sie? *"
                              placeholder="Deutsch, Englisch..."
                              outlined
                              dense
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-card>
                          <v-card-title>
                            <h3>Call To Action</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-text-field
                              v-model="expertsProfile.item.callToAction"
                              label="Definieren Sie eine Handlungsaufforderung für Ihre Kunden oder überzeugen Sie durch einen kurzen Slogan"
                              placeholder="Werden Sie Profi in ... ich helfen Ihnen bei... Schreiben Sie mir jetzt eine E-Mail..."
                              outlined
                              dense
                            ></v-text-field>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col>
                        <v-card>
                          <v-card-title>
                            <h3>Meine Expertise</h3>
                          </v-card-title>
                          <v-card-text>
                            <v-tiptap
                              v-model="expertsProfile.item.vita"
                              label="Beschreiben Sie Ihre Expertise, was können Sie Ihren Kunden bieten? Welche Erfahrungen haben Sie?*"
                              placeholder="Ich habe bereits mehrere Expeditionen durchgeführt und habe viel Erfahrung in der Wildnis gesammelt..."
                            >
                            </v-tiptap>
                          </v-card-text>
                        </v-card>
                      </v-col>
                    </v-row>

                    <v-row>
                      <v-col md="12" sm="12">
                        <v-btn
                          color="primary"
                          @click="updateProfile()"
                          class="mb-2"
                          block
                          :disabled="!valid"
                        >
                          Profil aktualisieren
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { getUsername } from "../../helpers";
import NoProfile from "./NoProfile";
import moment from "moment";

export default {
  created() {
    this.reload();
    this.reloadCategories();
  },
  mounted() {
    this.$root.$on("reload_profile_data", () => {
      this.reload();
    });

    this.$root.$on("reload_categories_data", () => {
      this.reloadCategories();
    });
  },
  components: {
    NoProfile,
  },
  data() {
    return {
      valid: false,
      submitted: false,
      file: null,
      modal: false,
      files: new FormData(),
      selectedCategories: [],
      sex: [
        { text: "Männlich", value: 0 },
        { text: "Weiblich", value: 1 },
        { text: "Divers", value: 2 },
      ],
      confirmBar: false,
      countries: [
        { text: "Deutschland", value: "DE" },
        { text: "Österreich", value: "AT" },
        { text: "Schweiz", value: "CH" },
      ],
    };
  },
  computed: {
    username() {
      return getUsername();
    },
    expertsProfile() {
      let data = this.$store.state.experts.myself;
      return data;
    },
    isLoading() {
      return this.$store.state.experts.myself.loading;
    },
    uploadSuccesses() {
      let x = this.$store.state.experts.uploaded;
      return x;
    },
    categories() {
      var data = this.$store.state.categories.all.items;
      console.table(data);
      return data;
    },
    uploadErrors() {
      return this.$store.state.experts.failed;
    },
  },
  methods: {
    parseDate(date) {
      //Firefox fix
      if (date) {
        let dateCorrect = null;
        dateCorrect = date.replace("+0100 +0100", "");
        dateCorrect = dateCorrect.replace("+0000 UTC", "");
        dateCorrect = dateCorrect.replace(" ", "T").trim() + "Z";
        console.log(dateCorrect);
        let formatted = moment(dateCorrect).format("YYYY-MM-DD");
        return formatted;
      } else {
        return "";
      }
    },
    getStateColor() {
      if (this.expertsProfile.item == null) {
        return "grey";
      }

      let state = this.expertsProfile.item;

      if (state.isPublic) {
        return "green";
      } else {
        if (state.pendingUnlock) {
          return "blue";
        }
      }
      return "grey";
    },
    async reloadCategories() {
      await this.$store.dispatch("categories/getAll");
    },
    maxSubscriptionOverload() {
      let maxCategories = this.getMaxSubscription();
      if (this.selectedCategories.length > maxCategories) {
        return true;
      }
      return false;
    },
    getMaxSubscription() {
      let subscription = this.$store.state.experts.myself.item.subscription;

      let maxCategories = 1;

      if (subscription != null && subscription.product != null) {
        maxCategories = subscription.product.maxCategories;
      }

      return maxCategories;
    },

    checkSubscription() {
      let maxCategories = this.getMaxSubscription();
      if (this.maxSubscriptionOverload) {
        this.$root.$emit(
          "show_snackbar",
          "Sie haben zu viele Kategorien ausgewählt. Bitte wählen Sie maximal " +
            maxCategories +
            " Kategorie(n) aus oder upgraden Sie Ihren Account."
        );
      }

      return true;
    },

    async updateProfile() {
      if (!this.checkSubscription()) {
        return;
      }

      let dto = {
        id: this.expertsProfile.item.id,
        accountMail: this.expertsProfile.item.accountMail,
        userId: this.expertsProfile.item.userId,
        firstName: this.expertsProfile.item.firstName,
        lastName: this.expertsProfile.item.lastName,
        contactMail: this.expertsProfile.item.contactMail,
        phone: this.expertsProfile.item.phone,
        mobile: this.expertsProfile.item.mobile,
        street: this.expertsProfile.item.street,
        plz: this.expertsProfile.item.plz,
        city: this.expertsProfile.item.city,
        country: this.expertsProfile.item.country,
        website: this.expertsProfile.item.website,
        Social_Facebook: this.expertsProfile.item.social_Facebook,
        Social_Instagram: this.expertsProfile.item.social_Instagram,
        Social_Twitter: this.expertsProfile.item.social_Twitter,
        Social_LinkedIn: this.expertsProfile.item.social_LinkedIn,
        Social_Youtube: this.expertsProfile.item.social_Youtube,
        caption: this.expertsProfile.item.caption,
        description: this.expertsProfile.item.description,
        callToAction: this.expertsProfile.item.callToAction,
        birthday: this.expertsProfile.item.birthday,
        languages: this.expertsProfile.item.languages,
        geschlecht: this.expertsProfile.item.geschlecht,
        vita: this.expertsProfile.item.vita,
        embed_Youtube_ChannelTrailer:
          this.expertsProfile.item.embed_Youtube_ChannelTrailer,
      };

      await this.$store
        .dispatch("experts/update", dto)
        .then(() => {
          this.$store
            .dispatch("experts/truncateCategories", dto)
            .then(() => {
              this.selectedCategories.forEach(async (element) => {
                let dto2 = {
                  expertId: this.expertsProfile.item.id,
                  categoryId: element,
                };
                await this.$store.dispatch("experts/addCategory", dto2);
              });
              this.$root.$emit("show_snackbar", "Profil wurde aktualisiert");
            })
            .catch(() => {
              this.$root.$emit(
                "show_snackbar",
                "Fehler 4 beim Aktualisieren des Profils. Bitte schreiben Sie uns eine E-Mail, danke."
              );
            });
        })
        .catch(() => {
          this.$root.$emit(
            "show_snackbar",
            "Fehler 1 beim Aktualisieren des Profils. Bitte schreiben Sie uns eine E-Mail, danke."
          );
        });
    },

    async submitProfile() {
      if (this.expertsProfile.item.isPublic != true) {
        let dto = {
          id: this.expertsProfile.item.id,
        };

        await this.$store
          .dispatch("experts/activateRequest", dto)
          .then(() => {
            this.$root.$emit(
              "show_snackbar",
              "Profil wurde zur Freigabe gesendet"
            );
            this.reload();
          })
          .catch(() => {
            this.$root.$emit(
              "show_snackbar",
              "Fehler beim Senden des Profils. Bitte schreiben Sie uns eine E-Mail, danke."
            );
          });
      }
    },
    newImage() {
      this.submitted = true;

      for (let f of this.file) {
        this.$root.$emit(
          "show_snackbar",
          "Bitte warten. Bild wird hochgeladen: " + f.name
        );

        let formData = new FormData();
        formData.append("UploadImage", f);
        formData.append("ExpertId", parseInt(this.expertsProfile.item.id));

        this.$store
          .dispatch("experts/updatePicture", formData)
          .then(() => {
            this.$root.$emit(
              "show_snackbar",
              "Bild wurde hochgeladen: " + f.name
            );
            this.$store.dispatch("experts/resetUploads");
            this.reload();
          })
          .catch(() => {
            this.$root.$emit(
              "show_snackbar",
              "Fehler beim Hochladen des Bildes. Passen Sie die Größe an und versuchen Sie es erneut. Danke."
            );
          });
      }

      this.reload();
    },

    async reload() {
      this.submitted = false;
      await this.$store.dispatch("experts/getMyProfile");
    },
    getAbsoluteImageUrl(image) {
      return process.env.VUE_APP_API_ROUTE + image;
    },
  },
  watch: {
    expertsProfile: function (newVal, oldVal) {
      // watch it
      console.log("Prop changed: ", newVal, " | was: ", oldVal);
      if (newVal.item != null) {
        this.selectedCategories = newVal.item.categories;
      }
    },
  },
};
</script>
