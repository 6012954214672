import Vue from 'vue';
import Vuex from 'vuex';

import { alert } from './alert.module';
import { experts } from './experts.module';
import { categories } from './categories.module';
import { proposals } from './proposals.module';
import { products } from './products.module';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        alert,
        experts,
        categories,
        proposals,
        products
    }
});