<template>
    <div>
    <v-container>
  <v-row>
    <v-col>
      <p>Sie haben noch kein Experten-Profil angelegt.</p>
      <p>
        Wenn Sie über fundiertes Wissen und Erfahrung in einem bestimmten
        Bereich verfügen, haben Sie die Möglichkeit, Ihr Know-how als Experte
        anderen zur Verfügung zu stellen und dafür bezahlt werden. Indem Sie
        sich als Experte anlegen, können Sie geführte Touren, Kurse oder
        Dienstleistungen anbieten, um anderen Menschen zu helfen, ihr Wissen und
        ihre Fähigkeiten in einem bestimmten Bereich zu verbessern.
      </p>
      <p>
        Die Basis Mitgliedschaft ist kostenlos. Sollten Sie mehr Funtionen
        benötigen, upgraden Sie Ihr Profil bitte auf eine
        Standard oder Premium Mitgliedschaft.
      </p>
      <p>
        <router-link to="/subscription"
          >Hier finden Sie alle Informationen zu den Mitgliedschaften</router-link
        >
      </p>
      <p>
        <v-btn @click="createProfile()" color="primary" class="mt-2"
          >Hier klicken um Ihr jetzt Experten Profil zu erstellen.</v-btn
        >
      </p>
    </v-col>
  </v-row>
</v-container>
    </div>
</template>

<script>
export default {
  methods: {
    createProfile() {
      let dto = {};

      this.$store.dispatch("experts/createProfile", dto).then(() => {
        this.$root.$emit("reload_profile_data");
      });
    },
  },
};
</script>
