<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col>
          <h2>Mitgliedschaften</h2>
          <p>
            Hier finden Sie alle Informationen zu den Mitgliedschaften. Sollten
            Sie Fragen haben, können Sie uns gerne jederzeit kontaktieren.
          </p>
          <p><b>Ihre aktuelle Mitgliedschaft:</b> {{ currentSubscription }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>
            * Alle Preise verstehen sich inklusive der gesetzlichen Mehrwertsteuer (innerhalb Deutschland). Pakete sind monatlich kündbar.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-for="product in products.items" :key="product.id">
          <v-card
            :loading="isLoading"
            class="mx-auto my-12"
            max-width="374"
            min-height="600"
            :elevation="product.highlight ? 12 : 2"
          >
          
            <v-img
              :src="product.productImage"
              height="200px"
              cover
            ></v-img>
            <v-card-title             :class="product.highlight ? 'green lighten-2' : ''">{{ product.name }}</v-card-title>
            <v-card-subtitle             :class="product.highlight ? 'green lighten-2' : ''">
              <v-row align="center" class="mx-0">
                <template v-if="product.isFree">
                  <div class="my-4 text-subtitle-1">Kostenlos</div>
                  
                </template>
                <template v-else>
                  <div class="my-4 text-subtitle-1">{{ product.price }} € / Monat *</div>
                </template>
              </v-row>
            </v-card-subtitle>
            <v-divider></v-divider>

            <v-card-text>
              <div v-html="product.html"></div>
            </v-card-text>
            <v-spacer></v-spacer>
            <v-divider></v-divider>
            <v-card-actions>
              <template v-if="product.isFree">
                <template v-if="!expertsProfile.item.subscription">
                  <v-btn color="info" disabled
                  >Ihr aktueller Plan</v-btn
                >
                </template>
                <template v-else>
                  <v-btn color="info"
                    >Downgrade zu kostenlos</v-btn
                  >
                </template>
              </template>
              <template v-else>
                <template v-if="!expertsProfile.item.subscription">
                  <v-btn color="info" @click="openUrl(product.digistoreUrl)"
                  >Mitgliedschaft buchen</v-btn
                >
                </template>

                

                <template v-else-if="currentSubscriptionId == product.digistoreProductId">
                  <v-btn color="info" disabled
                    >Ihr aktueller Plan</v-btn
                  >
                </template>
                <template v-else>
                  <v-btn color="info" @click="openUrl(getUpgradeUrl(product.digistoreUpgradeUrl))"
                    >Upgrade zu diesem Plan</v-btn
                  >
                </template>
              </template>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style>
  ul {
    list-style: none;
  }
  li {
    margin-bottom: 10px;
  }
</style>
  
  <script>
export default {
  created() {
    this.getData();
  },
  mounted() {
    this.$root.$on("reload_products_data", () => {
      this.getData();
    });
  },
  computed: {
    expertsProfile() {
      let data = this.$store.state.experts.myself;
      return data;
    },
    currentSubscription() {
      console.info("---------------------");
      console.info(this.expertsProfile);
      console.info("---------------------");

      if (this.expertsProfile.loading) {
        return "Lade...";
      }
      if (!this.expertsProfile.item) {
        return "Standard Mitgliedschaft";
      }
      if (!this.expertsProfile.item.subscription) {
        return "Standard Mitgliedschaft";
      } 
      else {
        return this.expertsProfile.item.subscription.digistoreName;
      }
    },
    currentSubscriptionId() {
      console.info("---------------------");
      console.info(this.expertsProfile);
      console.info("---------------------");

      if (this.expertsProfile.loading) {
        return 0;
      }
      if (!this.expertsProfile.item) {
        return 0;
      }
      if (!this.expertsProfile.item.subscription) {
        return 0;
      } 
      else {
        return this.expertsProfile.item.subscription.digistoreIdentifier;
      }
    },
    products() {
      let data = this.$store.state.products.all;
      console.info(data);
      return data;
    },
    isLoading() {
      return this.$store.state.products.all.loading;
    },
  },
  methods: {
    getUpgradeUrl(url) {
      return url.replace("BESTELLID", this.expertsProfile.subscription.bestellid);
    },
    async getData() {
      await this.$store.dispatch("experts/getMyProfile");
      await this.$store.dispatch("products/getAll");
    },

    openUrl(url) {
      window.open(url, "_blank");
    },
  },
  watch: {
      expertsProfile: function (newVal, oldVal) {
        // watch it
        console.log("Prop changed: ", newVal, " | was: ", oldVal);
        if (newVal.item != null) {
          this.selectedCategories = newVal.item.categories;
        }
      },
  },
};
</script>
  