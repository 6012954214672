//import * as Sentry from "@sentry/vue";

import {
    authHeader,
    authHeaderUndefined
} from '../helpers';

export const expertsService = {
    getAll,
    createProfile,
    updateExisting,
    deleteExisting,
    getMyProfile,
    newImage,
    activateRequest,
    truncateCategories,
    addCategory
};


const route = '/api/Experts'

function getAll() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route, requestOptions).then(handleResponse);
}

function getMyProfile() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/GetMyProfile', requestOptions).then(handleResponse);
}

function activateRequest(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    console.info(payload);
    return fetch(process.env.VUE_APP_API_ROUTE + route + "/ActivateRequest/" + payload.id, requestOptions).then(handleResponse);
}


function newImage(image) {
    const requestOptions = {
        method: 'POST',
        headers: authHeaderUndefined(),
        body: image
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/UpdateProfilePicture', requestOptions).then(handleResponse);
}


function createProfile(payload) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader()
    };

    console.log(payload);

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/CreateProfile', requestOptions).then(handleResponse);
}

function updateExisting(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    console.info(payload);

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/UpdateExpert/' + payload.id, requestOptions).then(handleResponse);
}


function deleteExisting(payload) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/' + payload.id, requestOptions).then(handleResponse);
}

function addCategory(payload) {
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(payload)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/AddExpertCategory', requestOptions).then(handleResponse);
}

function truncateCategories(payload) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
        body: JSON.stringify(payload.id)
    };

    return fetch(process.env.VUE_APP_API_ROUTE + route + '/TruncateExpertCategories/' + payload.id, requestOptions).then(handleResponse);
}


function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                localStorage.removeItem('vue-token');
                location.reload(true);               
            }

            const error = (data && data.message) || response.statusText;
            //this.$store.dispatch("alert/error", error);
            //Sentry.captureException(error);
            return Promise.reject(error);
        }

        return data;
    });
}