<template v-slot:default="dialog">
  <div>
    <v-container fluid>
      <v-form ref="form" v-model="valid">
        <v-parallax
          height="300"
          src="https://images.unsplash.com/photo-1534246357846-40b500934c14?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1471&q=80"
        ></v-parallax>
        <v-container style="margin-top: -18rem;">
          <v-row>
            <v-col col="12"></v-col>
            <v-col col="2" v-if="!Data.IsPublished">
              <!-- Button zum Veröffentlichen des Angebots -->
              <v-btn
                color="blue"
                class="mb-2 mt-3"
                block
                @click="publishProposal"
                :disabled="!Data.id || Data.IsPublished"
              >
                Angebot veröffentlichen
              </v-btn>
            </v-col>
            <v-col col="2" v-else>
              <!-- Button zum Schließen des Dialogs -->
              <v-btn color="red" class="mb-2 mt-3" block @click="unpublishProposal">Veröffentlichung aufheben</v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-card>
                <v-card-title>
                  <h2>Allgemeine Angebots-Informationen</h2>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="8">
                      <v-text-field
                        v-model="Data.Title"
                        :rules="inputRules"
                        label="Angebots Name *"
                        placeholder="z.B. Packrafting Einsteigerkurs"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="3">
                      <v-text-field
                        v-model="Data.PriceNetto"
                        :rules="inputRules"
                        label="Netto Preis *"
                        placeholder="z.B. 100,00"
                        required
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="1">
                      <v-select
                        v-model="Data.Currency"
                        :items="Currencies"
                        label="Währung *"
                        item-text="title"
                        item-value="key"
                        required
                      >
                      </v-select>
                    </v-col>
                    <v-col cols="12" md="12">
                      <v-select
                        v-model="Data.CategoryId"
                        :items="categories"
                        label="Kategorie *"
                        item-text="title"
                        item-value="id"
                        required
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-btn 
                color="primary" 
                @click="saveBasicInfo" 
                :disabled="!valid || !isBasicInfoValid || basicInfoSaved" :hidden="basicInfoSaved">
                Allgemeine Informationen speichern
              </v-btn>
            </v-col>
          </v-row>

          <!-- Sektion für Bilder und weitere Informationen wird nur angezeigt, wenn die allgemeinen Informationen gespeichert wurden -->
          <v-row v-if="basicInfoSaved">
            <v-col cols="12" md="12">
              <v-card>
                <v-card-title>
                  <h2>Bilder zum Angebot</h2>
                  <v-alert border="left" prominent class="mt-5">
                    Hier können Sie Bilder zum Angebot hinzufügen. Bitte beachten Sie, dass die Bilder
                    eine Größe von 600x400 Pixel haben sollten, um eine optimale Darstellung zu
                    gewährleisten. Laden Sie das Haupt- (bzw. Vorschau-) Bild zuerst hoch.
                  </v-alert>
                </v-card-title>
                <v-card-text>
                  <!-- File Input für Bilder -->
                  <v-file-input
                    v-model="newImages"
                    accept="image/*"
                    multiple
                    chips
                    label="Bilder"
                    prepend-icon="mdi-camera"
                    show-size
                    outlined
                    @change="onFileChange"
                  ></v-file-input>
                  <!-- Button zum Hochladen der Bilder -->
                  <v-btn color="primary" @click="uploadAllImages">Alle Bilder hochladen</v-btn>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <!-- Vorschau der Bilder -->
          <v-row v-if="Data.Images.length > 0">
          <v-col cols="3" v-for="(image, index) in Data.Images" :key="index">
            <v-img
              :src="image.url"
              max-width="100"
              max-height="100"
              contain
            ></v-img>
          </v-col>
        </v-row>

          <v-row v-if="basicInfoSaved">
            <v-col>
              <v-card>
                <v-card-title>
                  <h2>Ort des Kurses / Angebots</h2>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-switch
                        v-model="Data.IsOnline"
                        :label="
                          'Handelt es sich um ein reines Online Angebot? ' + (Data.IsOnline ? 'Ja' : 'Nein')
                        "
                      ></v-switch>
                    </v-col>

                    <v-col cols="12" md="12">
                      <v-select
                        v-model="Data.Country"
                        :items="countries"
                        label="Land"
                        item-text="text"
                        item-value="value"
                        :disabled="Data.IsOnline"
                      >
                      </v-select>
                      <v-text-field
                        v-model="Data.ZipCode"
                        label="Postleitzahl"
                        placeholder="z.B. 12345"
                        :disabled="Data.IsOnline"
                      ></v-text-field>
                      <v-text-field
                        v-model="Data.Street"
                        label="Straße"
                        placeholder="z.B. Musterstraße 1"
                        :disabled="Data.IsOnline"
                      ></v-text-field>
                      <v-text-field
                        v-model="Data.City"
                        label="Ort"
                        placeholder="z.B. Berlin"
                        :disabled="Data.IsOnline"
                      ></v-text-field>
                      <v-text-field
                        v-model="Data.MapsLink"
                        label="Teilen-Link zu Google Maps"
                        placeholder="z.B. https://goo.gl/maps/kmyhtFmpBb3YwpHJ8"
                        :disabled="Data.IsOnline"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>

          <v-row v-if="basicInfoSaved">
            <v-col>
              <v-card>
                <v-card-title>
                  <h2>Online Terminbuchung (Calendly Integration)</h2>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <p>
                        Wenn Sie einen Online Terminbuchungsdienst nutzen, können Sie
                        diesen hier einbinden. Die Teilnehmer können dann direkt über die
                        Webseite einen Termin buchen. Dazu müssen Sie sich bei Calendly
                        registrieren und einen Terminplaner erstellen. Die Url des
                        Terminplaners können Sie dann hier eintragen.
                      </p>
                      <v-switch
                        v-model="Data.UseCalendarly"
                        label="Calendly Integration nutzen?"
                      ></v-switch>
                      <v-text-field
                        v-model="Data.CalendarlyUrl"
                        label="Calendly Url"
                        placeholder="z.B. https://calendly.com/username/offername"
                        :disabled="!Data.UseCalendarly"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          
          <v-row v-if="basicInfoSaved">
            <v-col>
              <v-card>
                <v-card-title>
                  <h2>Angebotsbeschreibung / Informationen zum Kurs</h2>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="12">
                      <v-tabs
                        v-model="tab"
                        color="deep-purple-accent-4"
                        align-tabs="center"
                      >
                        <v-tab :value="0">Beschreibung *</v-tab>
                        <v-tab :value="1">Konditionen *</v-tab>
                        <v-tab :value="2">Kursinhalte *</v-tab>
                      </v-tabs>
                      <v-window v-model="tab">
                        <v-window-item :value="0">
                          <v-tiptap
                            v-model="Data.Description"
                            :uploadImage="uploadImage"
                            placeholder="Beschreiben Sie den Kurs. z.B. Was ist der Kurs, für wen ist der Kurs, etc."
                            height="600"
                          >
                          </v-tiptap>
                        </v-window-item>
                        <v-window-item :value="1">
                          <v-tiptap
                            v-model="Data.Conditions"
                            :uploadImage="uploadImage"
                            placeholder="Beschreiben Sie die Konditionen z.B. Anzahl der Tage, Anzahl der Teilnehmer, etc."
                            height="600"
                          >
                          </v-tiptap>
                        </v-window-item>
                        <v-window-item :value="2">
                          <v-tiptap
                            v-model="Data.Contents"
                            :uploadImage="uploadImage"
                            placeholder="Beschreiben Sie die Inhalte des Kurses z.B. welche Themen werden behandelt, welche Materialien werden benötigt, etc."
                            height="600"
                          >
                          </v-tiptap>
                        </v-window-item>
                      </v-window>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
          
          <v-btn color="primary" class="mb-2 mt-3" block @click="Save()" :disabled="!valid || !basicInfoSaved">
            Speichern
          </v-btn>
        </v-container>
      </v-form>
    </v-container>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

export default {
  setup() {
    const toast = useToast();
    return { toast };
  },
  data() {
    return {
      valid: false,
      tab: 0,
      Currency: "EUR",
      Currencies: [
        { key: "EUR", title: "EUR" },
        { key: "CHF", title: "CHF" },
      ],
      countries: [
        { text: "Deutschland", value: "DE" },
        { text: "Österreich", value: "AT" },
        { text: "Schweiz", value: "CH" },
      ],
      Data: {
        Id: 0,
        Title: "",
        Description: "",
        Contents: "",
        Conditions: "",
        PriceNetto: 0,
        Currency: "EUR",
        Images: [],
        IdentityId: this.UserId,
        CategoryId: 0,
        IsOnline: false,
        Country: "DE",
        ZipCode: "",
        Street: "",
        City: "",
        MapsLink: "",
        CalendarlyUrl: "",
        UseCalendarly: false,
        IsPublished: false
      },
      newImages: [],
      inputRules: [(v) => !!v || "Pflichtfeld"],
      basicInfoSaved: false
    };
  },
  methods: {
    unpublishProposal() {
      if (!this.Data.id) return;

      this.$store.dispatch("proposals/unpublish", this.Data.id).then(() => {
        this.$root.$emit("reload_proposals_data");
        this.$toast.success("Angebot wurde zurückgezogen");
        this.$router.push({ name: "proposals" });
      });
    },
    publishProposal() {
      if (!this.Data.id) return;

      this.$store.dispatch("proposals/publish", this.Data.id).then(() => {
        this.$root.$emit("reload_proposals_data");
        this.$toast.success("Angebot wurde veröffentlicht");
        this.$router.push({ name: "proposals" });
      });
    },
    async uploadImage(file) {
      try {
        let id = this.Data.Id;
        const url = await this.$store.dispatch('proposals/uploadImage', { file, id });
        return url;
      } catch (error) {
        console.error("Fehler beim Hochladen des Bildes:", error);
        return null;
      }
    },
    onFileChange(files) {
  this.newImages = files;
  console.log('Dateien ausgewählt:', this.newImages); // Debugging-Log
},
async uploadAllImages() {
  try {
    const uploadedImages = await Promise.all(
      Array.from(this.newImages).map(async (file) => {
        if (file instanceof File) {
          const url = await this.uploadImage(file);
          return { name: file.name, url };
        } else {
          console.error('Ungültiger Dateityp:', file);
          return { name: 'Unbekannt', url: '' };
        }
      })
    );

    this.Data.Images = uploadedImages.map(img => img.url);

    console.log('Aktualisierte Data.Images:', this.Data.Images); // Debugging-Log

    this.$toast.success('Bilder wurden erfolgreich hochgeladen!');
  } catch (error) {
    console.error("Fehler beim Hochladen der Bilder:", error);
    this.$toast.error('Fehler beim Hochladen der Bilder.');
  }
},
    Reset() {
      this.Data.Title = "";
      this.Data.Description = "";
      this.Data.Contents = "";
      this.Data.Conditions = "";
      this.Data.Images = [];
      this.Data.IdentityId = this.UserId;
      this.Data.CategoryId = 0;
      this.Data.IsOnline = false;
      this.Data.Country = "DE";
      this.Data.ZipCode = "";
      this.Data.Street = "";
      this.Data.City = "";
      this.Data.MapsLink = "";
      this.Data.CalendarlyUrl = "";
      this.Data.UseCalendarly = false;
      this.newImages = [];
      this.basicInfoSaved = false;
    },
    async saveBasicInfo() {
      if (this.$refs.form && this.$refs.form.validate()) {
        let dto = {
          Title: this.Data.Title,
          Description: this.Data.Description,
          Contents: this.Data.Contents,
          Conditions: this.Data.Conditions,
          PriceNetto: this.Data.PriceNetto,
          Currency: this.Data.Currency,
          Images: this.Data.Images,
          CategoryId: this.Data.CategoryId,
          IsOnline: this.Data.IsOnline,
          Country: this.Data.Country,
          ZipCode: this.Data.ZipCode,
          Street: this.Data.Street,
          City: this.Data.City,
          MapsLink: this.Data.MapsLink,
          CalendarlyUrl: this.Data.CalendarlyUrl,
          UseCalendarly: this.Data.UseCalendarly,
          IsPublished: false
        };

        try {
          await this.$store.dispatch("proposals/create", dto);
          this.basicInfoSaved = true;
          this.Data.Id = this.$store.state.proposals.default.item.id;
          this.$toast.success('Allgemeine Informationen wurden erfolgreich gespeichert!');
          console.log('Data.Id:', this.Data.Id); // Debugging-Log
        } catch (error) {
          console.error("Fehler beim Speichern der allgemeinen Informationen:", error);
        }
      }
    },
    Save() {

      //a dto without images
      let dto = {
        Id: this.Data.Id,
        Title: this.Data.Title,
        Description: this.Data.Description,
        Contents: this.Data.Contents,
        Conditions: this.Data.Conditions,
        PriceNetto: this.Data.PriceNetto,
        Currency: this.Data.Currency,
        CategoryId: this.Data.CategoryId,
        IsOnline: this.Data.IsOnline,
        Country: this.Data.Country,
        ZipCode: this.Data.ZipCode,
        Street: this.Data.Street,
        City: this.Data.City,
        MapsLink: this.Data.MapsLink,
        CalendarlyUrl: this.Data.CalendarlyUrl,
        UseCalendarly: this.Data.UseCalendarly,
        IsPublished: this.Data.IsPublished
      };


      this.$store.dispatch("proposals/update", dto).then(() => {
        this.$root.$emit("reload_proposals_data");
        this.Reset();
        this.$router.push({ name: "proposals" });
      }).catch((error) => {
        console.error("Fehler beim Speichern der vollständigen Angebotsdaten:", error);
      });
    },
    createObjectURL(file) {
  if (file instanceof Blob || file instanceof File) {
    if (window.URL && window.URL.createObjectURL) {
      return window.URL.createObjectURL(file);
    }
  }
  console.error('Ungültiges Argument für createObjectURL:', file);
  return '';
}
  },
  computed: {
    categories() {
      let data = this.$store.state.categories.all.items;
      console.info("categories", data);
      return data;
    },
    isLoading() {
      return this.$store.state.categories.all.loading;
    },
    isBasicInfoValid() {
      return this.Data.Title && this.Data.PriceNetto && this.Data.Currency && this.Data.CategoryId;
    }
  },
};
</script>
