import { proposalsService } from '../services';

export const proposals = {
    namespaced: true,
    state: {
        all: {},
        selected: {},
        created: {},
        default: {},
        uploaded: [], // Hier initialisieren
        failed: [] // Falls du auch failed initialisieren möchtest
    },
    actions: {
        getAll({ commit }, id) {
            commit('getAllRequest');

            return new Promise((resolve, reject) => {
                proposalsService.getAll(id)
                .then(
                    payload => {
                        commit('getAllSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getAllFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },


        getSingle({ commit }, id) {
            commit('getRequest');

            return new Promise((resolve, reject) => {
                proposalsService.getSingle(id)
                .then(
                    payload => {
                        commit('getSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('getFailure', error)
                        reject(error)
                    } 
                )
            });
            
        },
    
        unpublish({ commit }, id) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                proposalsService.unpublishProposal(id)
                .then(
                    payload => {
                        commit('defaultSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('defaultFailure', error)
                        reject(error)
                    } 
                )
            }
        )},

        publish({ commit }, id) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                proposalsService.publishProposal(id)
                .then(
                    payload => {
                        commit('defaultSuccess', payload)
                        resolve(payload)
                    }, 
                    error => {
                        commit('defaultFailure', error)
                        reject(error)
                    } 
                )
            }
        )},

        delete({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                proposalsService.deleteExisting(dto)
                    .then(
                        payload => {
                            commit('defaultRequest', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultRequest', error);
                            reject(error)
                        }
                    );
            })
        },

        update({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                proposalsService.updateExisting(dto)
                    .then(
                        payload => {
                            commit('defaultRequest', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultRequest', error);
                            reject(error)
                        }
                    );
            })
        },


        uploadImage({ commit }, { file, id }) {
            commit('defaultRequest');
            return new Promise((resolve, reject) => {
              proposalsService.uploadImage(file, id)
                .then(
                  payload => {
                    commit('uploadedSuccess', payload);
                    resolve(payload);
                  },
                  error => {
                    commit('uploadedFailed', error);
                    reject(error);
                  }
                );
            });
          },

        create({
            commit
        }, dto) {
            commit('defaultRequest');

            return new Promise((resolve, reject) => {
                proposalsService.create(dto)
                    .then(
                        payload => {
                            commit('defaultSuccess', payload);
                            resolve(payload)
                        },
                        error => {
                            commit('defaultFailure', error);
                            reject(error)
                        }
                    );
            })
        },
    },
    mutations: {

        defaultRequest(state) {
            state.default = {
                loading: true
            }; 
        },
        defaultSuccess(state, payload) {
            state.default = {
                item: payload,
                loading: false
            }; 
        },
        defaultFailure(state, error) {
            state.default = {
                error,
                loading: false
            }; 
        },

        uploadedSuccess(state, payload) {
            let info = "";
            // Angenommen, payload ist ein einfaches Objekt
            if (payload && payload.ProfilePicture) {
                info = payload.ProfilePicture.name || ""; // Sicherstellen, dass name existiert
            }
            state.uploaded.push(info);
        },
        uploadedFailed(state, error) {
            let info = "";
            // Angenommen, error ist ein einfaches Objekt
            if (error && error.ProfilePicture) {
                info = error.ProfilePicture.name || ""; // Sicherstellen, dass name existiert
            }
            state.failed.push(info);
        },

        getMyProfileRequest(state) {
            state.myself = { loading: true };
        },
        getMyProfileSuccess(state, user) {
            state.myself = { item: user };
        },
        getMyProfileFailure(state, error) {
            state.myself = { error };
        },


        getRequest(state) {
            state.selected = { loading: true };
        },
        getSuccess(state, user) {
            state.selected = { item: user };
        },
        getFailure(state, error) {
            state.selected = { error };
        },
        getAllRequest(state) {
            state.all = { loading: true };
        },
        getAllSuccess(state, users) {
            state.all = { items: users };
        },
        getAllFailure(state, error) {
            state.all = { error };
        }
    }
}